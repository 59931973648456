.navbar {
  display: flex;
  max-width: 770px;
  align-items: center;
  background: #fff;
  color: #333;
  height: 60px;
  margin: 5px auto;
  padding: 0;
}

.navbar a {
  color: #333;
  margin-right: 20px;
}

.navbar a:hover {
  color: #000;
}

.navbar ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.navbar li {
  margin-left: 15px;
}

.navbar img {
  cursor: pointer;
}

.toolbar {
  justify-content: space-between;
}

@media screen and (max-width: 750px) {
  .navbar {
    flex-direction: column;
  }

  .navbar__menu {
    flex-direction: column;
  }
}

@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400&display=swap);
.Navbar_navbar__3KWoz {
  display: flex;
  max-width: 770px;
  align-items: center;
  background: #fff;
  color: #333;
  height: 60px;
  margin: 5px auto;
  padding: 0;
}

.Navbar_navbar__3KWoz a {
  color: #333;
  margin-right: 20px;
}

.Navbar_navbar__3KWoz a:hover {
  color: #000;
}

.Navbar_navbar__3KWoz ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.Navbar_navbar__3KWoz li {
  margin-left: 15px;
}

.Navbar_navbar__3KWoz img {
  cursor: pointer;
}

.Navbar_toolbar__2UxXB {
  justify-content: space-between;
}

@media screen and (max-width: 750px) {
  .Navbar_navbar__3KWoz {
    flex-direction: column;
  }

  .Navbar_navbar__menu__1uE1E {
    flex-direction: column;
  }
}

.Layout_container__1SPwL {
  max-width: 760px;
  margin: 10px auto;
  padding: 0 10px;
}

:root {
  --color-bg: #ffffff;
  --color-text: #08090a;
  --color-blue: #3b49df;
  --color-red: #ef5350;
  --color-orange: #ef6c00;
  --color-green: #4db6ac;
  --color-gray: #b5bdc4;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0 auto;
  font-family: 'Noto Sans', sans-serif;
  background-color: var(--color-bg);
  color: var(--color-text);
  /* margin-top: 50px; */
  /* max-width: 700px; */
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

img {
  max-width: 100%;
}

.ql-editor {
  max-width: 760px;
  min-height: 390px;
  padding: 0 !important;
}

/* Video aspec ratio for quil editor */
.ql-editor .ql-video {
  width: 752px;
  height: 400px;
}

@media only screen and (max-width: 768px) {
  .ql-editor .ql-video {
    width: 752px;
    height: 181px;
  }
}

input {
  display: inline-block;
  outline: none;
  border: none;
  font-size: 1.5rem;
  width: 100%;
  padding: 5px 10px;
}

fieldset {
  border: none;
  padding: 1rem 0;
  font-size: 1.25rem;
}

/* 

main {
  padding: 1rem 10vw;
}

h1 { font-size: 2rem; }
h2 { font-size: 1.5rem; }
h2 { font-size: 1.25rem; }


code {
  overflow-x: scroll;
}

/* Buttons */

/* .btn, button {
  background-color: var(--color-gray);
  border: none;
  color: var(--color-text);
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  font-family: 'Noto Sans', sans-serif;
  font-weight: bold;
  border-radius: 0.25rem;
  cursor: pointer;
  margin: 0.5rem 1rem 0.5rem 0;
}

@media only screen and (max-width: 768px) {
  button {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
}

button:hover {
  filter: brightness(90%);
}

button:disabled,
button[disabled]{
  filter: brightness(80%);
  cursor: not-allowed;
}

button.btn-google {
  background-color: white;
  color: var(--color-text);
}

.btn-google img {
  width: 30px;
  margin-right: 10px;
}

button.btn-logo {
  background-color: var(--color-text);
  color: white;
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
} */

/* Cards */

/* .card {
  padding: 2rem;
  margin: 1rem 0;
  background-color: white;
  border: 1px solid var(--color-gray);
  border-radius: 8px;
}

.card footer {
  display: flex;
}

.card-img-center {
  width:20%;
  display:block;
  margin:auto;
  border-radius: 50%;
  max-width: 150px;
}

.card-info {
  color: white;
  background: var(--color-blue);
} */

/* Loader */

/* .loader {
  border: 10px solid var(--color-bg); 
  border-top: 10px solid var(--color-blue); 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */

/* File uploads */

/* input[type="file"] {
  display: none;
}

.upload-snippet {
  width: 75%;
    margin-left: auto;
    background: white;
    padding: 5px;
    margin: 5px 0;
} */
/* Utilities */

/* .push-left {
  margin-left: auto;
}

*/
.text-button {
  font-weight: bold;
  color: var(--color-bg);
}

.text-sm {
  font-size: 0.85rem;
}

.text-danger {
  font-weight: bold;
  color: var(--color-orange);
}

.text-success {
  font-weight: bold;
  color: var(--color-green);
}

.text-info {
  font-weight: bold;
  color: var(--color-blue);
}

.menu {
  width: 150px;
  display: flex;
  justify-content: space-between;
}

.post-menu {
  margin: 13px;
}

.box {
  display: flex;
  justify-content: space-between;
}

.box-center {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
}

.hidden {
  display: none;
}

/*
  Quill Image Uploader
*/
.image-uploading {
  position: relative;
  display: inline-block;
}

.image-uploading img {
  max-width: 98% !important;
  filter: blur(5px);
  opacity: 0.3;
}

.image-uploading::before {
  content: '';
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #1e986c;
  z-index: 1;
  -webkit-animation: spinner 0.6s linear infinite;
          animation: spinner 0.6s linear infinite;
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

